import { getRecoil, setRecoil } from "recoil-nexus";
import { loadedState } from "./atoms";
import { CLIENT, EVENT_TAGS, GLOBAL_CONST, PLATFORM, REWARD_OFFERING } from "./constants";
import semver from "semver";
import { EventLogger } from "./common/utils/EventLogger";

type RewardKeys = keyof typeof GLOBAL_CFG;

export const fromPublic = (asset: string = "") => {
  return process.env.PUBLIC_URL + asset;
};

export const delay = (t: number) => new Promise((resolve) => setTimeout(resolve, t));

export const redirect = (url?: string): Promise<void> => {
  EventLogger.logInfoEvent("Redirect issued", {
    url,
    event: "Redirect",
    rewardId: GLOBAL_CONST.REWARDS_ID,
  });
  /**
   * An artificial delay, because often this is associated with a button/link click also, WKWebview doesn't accept
   * two events firing at the same time.
   */
  return delay(Math.random() * 500).then(() => {
    url && window.location.replace(url);
  });
};

export const redirectLoaded = () => {
  if (getRecoil(loadedState)) return;
  redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/loaded`);
  setRecoil(loadedState, true);
};

export const redirectError = () => {
  redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/error`);
};

export const enableBackgroundOverlay = () => {
  setRecoil(loadedState, false);
  // disable loaded state after 10 seconds. This is in case the loadedState was not set back to false for some reason.
  setTimeout(() => {
    setRecoil(loadedState, true);
  }, 10000);
}

export const globalErrorHandler = (
  event: string | Event,
  source: string | undefined,
  lineno: number | undefined,
  colno: number | undefined,
  error: unknown,
) => {
  const data = { event, source, lineno, colno, error };
  console.error(`Global error occurred: ${JSON.stringify(data, null, 2)}`);
  EventLogger.logEvent("ERROR", "Global error occurred.", { otherInfo: data }).then(() => {
    redirectError();
  });
};

/**
 * All elements tagged with data attribute "data-url" will be wired with a custom app callback protocol.
 *
 * Elements tagged with "data-analytics" provides a key which is then looked up via the global
 * analytics object defined in analytics.js file.
 */
export function wireCallbacks() {
  const customLinkElements = getElementsByDataAttributeWithNoCustomCallbacks("[data-url]", EVENT_TAGS.LINK);
  for (const cle of customLinkElements) {
    // @ts-ignore
    const { url } = cle.dataset;
    cle.addEventListener("click", () => {
      redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/link?url=${encodeURIComponent(url!)}`);
    });
    tagElementCustomCallbackAdded(cle, EVENT_TAGS.LINK);
  }

  // A "redirect" just takes the path as it is, such as "onboarding-complete".
  const customRedirectElements = getElementsByDataAttributeWithNoCustomCallbacks(
    "[data-redirect]",
    EVENT_TAGS.REDIRECT,
  );
  for (const cre of customRedirectElements) {
    cre.addEventListener("click", () => {
      // @ts-ignore
      redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/${cre.dataset.redirect}`);
    });
    tagElementCustomCallbackAdded(cre, EVENT_TAGS.REDIRECT);
  }

  // Wire up analytics callback.
  const analyticsElements = getElementsByDataAttributeWithNoCustomCallbacks("[data-analytics]", EVENT_TAGS.ANALYTICS);
  for (const ae of analyticsElements) {
    ae.addEventListener("click", () => {
      // @ts-ignore
      sendAnalytics(ae.dataset.analytics);
    });
    tagElementCustomCallbackAdded(ae, EVENT_TAGS.ANALYTICS);
  }
}

// Preloads an image asset to ensure a close "native" app experience.
export function cacheImage(url: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      window.cachedImages = window.cachedImages || [];
      window.cachedImages.push(img);
      resolve();
    };
    img.onerror = (event) => {
      reject({ msg: `Failed to load image: ${url}`, event });
    };
  });
}

/**
 * Preloads a lottie json and stores it in the global window.cachedLottie[] object.
 * e.g. [{url: "http://localhost:3000/my-lottie.json", data: {...}}]
 */
export async function cacheLottie(url: string): Promise<void> {
  const response = await fetch(url);
  const data = await response.json();
  window.cachedLottie = window.cachedLottie || [];
  window.cachedLottie.push({ url, data });
}

// Retrieve lottie json from global cached array.
export function getLottieJson(url: string): Object {
  const cachedLottie = window.cachedLottie.find((cl) => cl.url === url);
  return cachedLottie!.data;
}

// get the correct analytics config based on client and version.
// Currently, we need to cater for legacy config for LinktGO. LinktGO will eventually support the
// default config, so we will use app version to determine which config to return.
function analyticsConfig(): { [key: string]: any } {
  // GLOBAL_ANALYTICS_LEGACY_LINKTGO_APP_MAX_VERSION: app versions equal or below this must use the legacy analytics config
  const isLegacyApp = semver.lte(GLOBAL_CONST.APP_VERSION, GLOBAL_ANALYTICS_LEGACY_LINKTGO_APP_MAX_VERSION);
  if (GLOBAL_CONST.CLIENT_ID === CLIENT.LINKTGO && isLegacyApp && GLOBAL_CONST.REWARDS_ID === REWARD_OFFERING.SHELL) {
    return GLOBAL_ANALYTICS_LEGACY;
  }
  return GLOBAL_ANALYTICS[GLOBAL_CONST.REWARDS_ID];
}

// Trigger an analytics callback to the app, payload is defined in the global analytics.js file.
export function sendAnalytics(key: string): Promise<void> {
  const analyticsEntry = analyticsConfig()[key];
  // Replace analytics placeholders.
  for (const k in analyticsEntry) {
    // eslint-disable-next-line no-template-curly-in-string
    analyticsEntry[k] = analyticsEntry[k].replace("${REWARD_ID}", GLOBAL_CONST.REWARDS_ID).replace("${CLIENT_ID}", GLOBAL_CONST.ANALYTICS_CLIENT);
  }
  // Constructor just happens to take a simple map object, easy.
  const queryString = new URLSearchParams(analyticsEntry).toString();
  return redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/analytics?${queryString}`);
}

function getElementsByDataAttributeWithNoCustomCallbacks(attribute: string, callbackType: EVENT_TAGS) {
  const elements = Array.from(document.querySelectorAll(attribute));
  // @ts-ignore
  return elements.filter((e) => !e[`customTag-${callbackType}`]);
}

function tagElementCustomCallbackAdded(element: any, callbackType: EVENT_TAGS) {
  element[`customTag-${callbackType}`] = true;
}

export const getSemanticVersion = (appVersionParam: string | null): string => {
  const appVersionSemanticRegex = appVersionParam ? appVersionParam.match(/[\d]*\.[\d]*\.[\d]*/) : "";
  return appVersionSemanticRegex ? appVersionSemanticRegex[0] : "0.0.0";
};

export function replaceGlobalConfigVars(value: ReplaceableString): string {
  let replacedStr = value as string;
  Object.entries(GLOBAL_CFG[GLOBAL_CONST.REWARDS_ID].vars[GLOBAL_CONST.REGION]).map(
    ([key, value]) => (replacedStr = replacedStr.replace(key, value)),
  );
  return replacedStr;
}

export function getPlatformFormattedText(text: string): string {
  return GLOBAL_CONST.PLATFORM === PLATFORM.ANDROID ? text.toUpperCase() : text;
}

export function getRedeemUrlWithContext(rewardsId: RewardKeys): string {
  const externalId = GLOBAL_CONST.DATA["externalId"] || "";
  const region = GLOBAL_CONST.REGION || "";
  const baseRedeemUrl = GLOBAL_CFG[rewardsId].redeem.redeemUrl;
  return baseRedeemUrl.replace("{EXTERNAL_ID}", externalId).replace("{REGION}", region);
}