import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { REWARD_OFFERING} from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";

function StarCarWashMain() {
  const redeemUrl = getRedeemUrlWithContext("star-car-wash");

  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.starcarwash}
        />
      </span>
    </>
  );
}

export default StarCarWashMain;
