import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";

function MyCarTacticalMain() {
  const redeemUrl = getRedeemUrlWithContext("mycar-tactical");

  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.mycartactical}
        />
      </span>
    </>
  );
}

export default MyCarTacticalMain;
