import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { REWARD_OFFERING } from "../../constants";

function EuropcarTacticalMain() {
    const redeemUrl = GLOBAL_CFG["europcar-tactical"].redeem.redeemUrl;

    return (
    <>
        <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
            <OnboardingPageExternalLink
                redeemUrl={redeemUrl}
                rewardId={REWARD_OFFERING.europcartactical}
            />
        </span>
    </>
    );
}

export default EuropcarTacticalMain;
