import React from "react";
import { useEffect, useState } from "react";
import { RewardsHubServicesApi } from "../../common/api/RewardsHubServicesApi";
import { COUPON_OFFERS, GLOBAL_CONST } from "../../constants";
import { EventLogger } from "../../common/utils/EventLogger";
import { getRedeemUrlWithContext, redirectError } from "../../utils";
import OnboardingPageExternalCouponLink from "../../common/components/OnboardingPageExternalCouponLink";

function SydneyAirportMain() {
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState("");

  const redeemUrl = getRedeemUrlWithContext("sydney-airport");

  useEffect(() => {
    async function getCoupon() {
      const api = new RewardsHubServicesApi();
      try {
        const coupon = await api.getCoupon({
          accountId: GLOBAL_CONST.DATA["accountId"],
          offerId: COUPON_OFFERS.sydneyairport,
        });
        setCoupon(coupon.couponCode);
      } catch (err) {
        await EventLogger.logErrorEvent("Error occured while fetching coupon:", err);
        redirectError();
      }
      setLoading(false);
    }

    getCoupon();
  }, []);

  return (
    <>
      {!loading && (
        <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
          <OnboardingPageExternalCouponLink
            partnerCfg={GLOBAL_CFG["sydney-airport"]}
            redeemUrl={redeemUrl}
            coupon={coupon}
            viewType={"external"}
          />
        </span>
      )}
    </>
  );
}

export default SydneyAirportMain;
