import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";

function BookingDotComMain() {
  const redeemUrl = getRedeemUrlWithContext("booking-dot-com");

  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.bookingdotcom}
        />
      </span>
    </>
  );
}

export default BookingDotComMain;