import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";

function SecureParkingMain() {
  const redeemUrl = getRedeemUrlWithContext("secure-parking");
  
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.secureparking}
        />
      </span>
    </>
  );
}

export default SecureParkingMain;